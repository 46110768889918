.feed-container {
  box-sizing: border-box;
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  padding: 10px;
}

#light {
  color: #161616;
  background-color: #fff;
}
#dark {
  background-color: #161616;
  color: #fff;
}

#light .page-content .main-content .leftside-bar {
  color: #161616;
  background-color: #fff;
}
#dark .page-content .main-content .leftside-bar {
  background-color: #161616;
  color: #fff;
}

#light .ccfr-header {
  color: #161616;
  background-color: #fff;
}
#dark .ccfr-header {
  background-color: #161616;
  color: #fff;
}
#light .top-item {
  color: #161616;
}
#dark .top-item {
  color: #fff;
}

#light .mob-menu {
  background-color: white;
}
#dark .mob-menu {
  background-color: #161616;
}

#light .bar1 {
  background-color: #161616;
}

#dark .bar1 {
  background-color: #fff1f1;
}

#light .bar2 {
  background-color: #161616;
}

#dark .bar2 {
  background-color: #fff1f1;
}

#light .bar3 {
  background-color: #161616;
}

#dark .bar3 {
  background-color: #fff1f1;
}

#light .hdr-main-nav {
  color: #161616;
  background-color: #fff;
}
#dark .hdr-main-nav {
  color: #fff;
  background-color: #161616;
}
#dark .link-dark {
  color: #fff;
}
#light .footer-content {
  background-color: #fff;
}
#dark .footer-content {
  background-color: #161616;
}

.main-body-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: -2px;

  background: url(../images/BackgroundImge.png);
  object-fit: cover;
  background-repeat: no-repeat;
}

.header-text {
  left: 14.67%;
  right: 66.4%;
  top: 11.7%;
  bottom: 85.59%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 22px;
  /* identical to box height, or 88% */

  letter-spacing: -0.408px;

  color: #000000;
}
.mt-9 {
  margin-top: 30%;
}

.clearable-input {
  position: relative;
  display: inline-block;
}
.clearable-input > input {
  padding-right: 1.4em;
}
.clearable-input > [data-clear-input] {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  font-size: 1.4em;
  padding: 0 0.2em;
  line-height: 1em;
  cursor: pointer;
}
.clearable-input > input::-ms-clear {
  display: none;
}

.spinner {
  height: 60px;
  width: 60px;
  margin: auto;
  display: flex;
  position: absolute;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}
.sub-boxes {
  height: 100%;
  word-break: break-all;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.img-radio {
  width: 100%;
  background-size: cover;
}
.img-video {
  width: 100%;
  height: 20vh;
  background-size: cover;
}
.text-grey {
  color: #818181;
}

.img-logo {
  width: 60px;
}
.img-logo1 {
  width: 40px;
}
.feed-font {
  font-size: 10px;
}

.feed-content img {
  width: 35px;
}
.feed-contents img {
  width: 25px;
}
.log-width {
  max-width: 400px !important;
}
.border-none {
  border-radius: 0px !important;
}
.img-div {
  max-width: 125px;
  width: 100%;
  margin-right: 15px;
  flex: 0 0 125px;
}
.img-div img {
  width: 100%;
}
.font-small {
  font-size: 12px;
  cursor: pointer;
}

.font-small-grey {
  font-size: 12px;
  color: #818181;
  cursor: pointer;
}
.dis-color {
  background-color: #e2e2e2;
  width: fit-content;
  height: fit-content;
}

.contents .donate-form .form-control {
  padding: 0 0 8px 0;
  border: 0;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.contents .profile .form-control {
  padding: 0 0 8px 0;
  border: 0;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.edit-member {
  padding: 2px;
  background-color: #bababa;
  border-radius: 4px;
  cursor: pointer;
}
.theme-mode {
  display: flex;
  justify-content: space-between;
}
.select-lang {
  display: flex;
  justify-content: space-between;
}

.language span.active {
  background: #dcdcdc;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  padding: 2px 9px;
}

.text-red {
  color: red;
}

#parent {
  width: 100vw;
  height: 50vh;
  margin-top: 10%;
  display: flex;
  align-items: center; /* vertical */
  justify-content: center; /* horizontal */
}
.text-green {
  color: green;
}

.input-mob {
  border: 0;
  border-bottom: solid 1px #c6c6c8;
  border-radius: 0;
  padding: 0.85rem 0;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #161616;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.name-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.back-wrapp {
  border-style: ridge;
  border-bottom: ridge;
  border-top: none;
  border-left: none;
  border-right: none;
}
.under-go {
  text-decoration: underline;
}

.special_modal-black .modal-content {
  background-color: #000;
  color: #fff;
}

.special_modal-white .modal-content {
  background-color: #fff;
  color: #000;
}
.filter-sort {
  padding: 7px 10px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.filter-sort svg {
  margin-left: 7px;
}
.feed-items {
  width: 100%;
}
